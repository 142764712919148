import { useState, useEffect, useContext, useRef } from "react";
import CurrentFolderContext from "../../utils/CurrentFolder";
import FolderDataContext from "../../utils/FolderData";
import LevelContext from "../../utils/Level";
import LanguageContext from "../../utils/Language";
// import { levelData } from "../../levels/level1/levelData";
// import { levelSuccessCriteria } from "../../levels/level1/levelSuccessCriteria";

const checkItemsCountEqualTo = (requiredTotalItemsCount, folderData) =>
  Object.keys(folderData).length === requiredTotalItemsCount;

const checkItemNameIsNot = (id, name, folderData) => {
  if (folderData[id]) {
    return !name.includes(folderData[id].title);
  } else {
    return false;
  }
};

const checkItemNameIs = (id, name, folderData) => {
  if (folderData[id]) {
    return name.includes(folderData[id].title);
  } else {
    return false;
  }
};

const checkChildrenArrayContain = (parentId, childrenId, folderData) => {
  return folderData[parentId].childrenId.includes(childrenId);
};

const checkChildrenArrayNotContain = (parentId, childrenId, folderData) => {
  return !folderData[parentId].childrenId.includes(childrenId);
};

const checkCurrentFolderId = (id, currentFolder) => {
  return currentFolder === id;
};

const checkFolderHistoryArray = (sequence, folderHistoryArray) => {
  // console.log(sequence);
  // console.log(folderHistoryArray);
  // console.log(folderHistoryArray.includes(sequence));
  function matchSubArray(folderHistoryArray, sequence) {
    const parentStr = folderHistoryArray.join("");
    const childStr = sequence.join("");
    // console.log(parentStr);
    // console.log(childStr);
    return parentStr.indexOf(childStr) !== -1;
  }
  // console.log(matchSubArray(folderHistoryArray, sequence));
  return matchSubArray(folderHistoryArray, sequence);
  // return folderHistoryArray.includes(sequence);
};

const checkSuccess = (
  levelSuccessCriteria,
  folderData,
  currentFolder,
  folderHistoryArray
) => {
  let successArray = [];

  if (levelSuccessCriteria.checkItemsCountEqualTo) {
    const result = checkItemsCountEqualTo(
      levelSuccessCriteria.checkItemsCountEqualTo_requiredTotalItemsCount,
      folderData
    );
    successArray.push(result);
  }

  if (levelSuccessCriteria.checkItemNameIsNot) {
    const result = checkItemNameIsNot(
      levelSuccessCriteria.checkItemNameIsNot_id,
      levelSuccessCriteria.checkItemNameIsNot_name,
      folderData
    );
    successArray.push(result);
  }

  if (levelSuccessCriteria.checkItemNameIs) {
    const result = checkItemNameIs(
      levelSuccessCriteria.checkItemNameIs_id,
      levelSuccessCriteria.checkItemNameIs_name,
      folderData
    );
    successArray.push(result);
  }

  if (levelSuccessCriteria.checkChildrenArrayContain) {
    const result = checkChildrenArrayContain(
      levelSuccessCriteria.checkChildrenArrayContain_parentId,
      levelSuccessCriteria.checkChildrenArrayContain_childrenId,
      folderData
    );
    successArray.push(result);
  }
  if (levelSuccessCriteria.checkChildrenArrayNotContain) {
    const result = checkChildrenArrayNotContain(
      levelSuccessCriteria.checkChildrenArrayNotContain_parentId,
      levelSuccessCriteria.checkChildrenArrayNotContain_childrenId,
      folderData
    );
    successArray.push(result);
  }

  if (levelSuccessCriteria.checkCurrentFolderId) {
    const result = checkCurrentFolderId(
      levelSuccessCriteria.checkCurrentFolderId_id,
      currentFolder
    );
    successArray.push(result);
  }

  if (levelSuccessCriteria.checkFolderHistoryArray) {
    const result = checkFolderHistoryArray(
      levelSuccessCriteria.checkFolderHistoryArray_include,
      folderHistoryArray
    );
    successArray.push(result);
  }

  if (successArray.length === 0 || successArray.includes(false)) {
    return false;
  } else {
    return true;
  }
};

export const LevelHandler = (props) => {
  const { language, setLanguage } = useContext(LanguageContext);
  const { level, setLevel } = useContext(LevelContext);
  const { currentFolder, setCurrentFolder } = useContext(CurrentFolderContext);
  const { folderData, setFolderData } = useContext(FolderDataContext);
  const folderHistoryArray = useRef([]);
  //store the current folder history, as developed in navBar
  //checkFolderHistoryArray
  //checkFolderHistoryArray_includes[3,0]
  useEffect(() => {
    folderHistoryArray.current.push(currentFolder);
  }, [currentFolder]);
  // console.log(folderHistoryArray);
  //validation of level:
  useEffect(() => {
    if (
      checkSuccess(
        props.initialData.levelSuccessCriteria,
        folderData,
        currentFolder,
        folderHistoryArray.current
      )
    ) {
      let timerFunc = setTimeout(() => {
        setLevel((previous) => ({ ...previous, success: true }));
      }, 2000);
      return () => clearTimeout(timerFunc);
    }
  }, [folderData, currentFolder, folderHistoryArray.current]);
  return null;
};

//import { memo } from "react";
import "./Folder.css";
import FolderDataContext from "../../utils/FolderData";
import { useContext, useState, useEffect } from "react";
// import { motion } from "framer-motion";
import LevelContext from "../../utils/Level";
//export const Folder = memo(function Folder({ title, yellow, preview }) {
//});
export const Folder = function Folder({
  id,
  type,
  title,
  picture,
  changeTitle,
  isSelected,
  isTargeted,
}) {
  const { folderData, setFolderData } = useContext(FolderDataContext);
  const { level, setLevel } = useContext(LevelContext);
  const [item, setItem] = useState(title);
  function handleChange(event) {
    const { value } = event.target;
    setItem(value);
  }
  //auto select input text when a rename operation start
  //https://blog.maisie.ink/react-ref-autofocus/
  const handleFocus = (event) => event.target.select();

  function handleClick(e) {
    if (changeTitle) {
      const event = e.srcElement.classList[0];
      if (event !== "inputBox") {
        const renamedFolder = {
          ...folderData[id],
          changeTitle: false,
          title: item,
        };
        setFolderData((folderData) => ({
          ...folderData,
          [id]: renamedFolder,
        }));
      }
    }
  }

  function handleEnter(e) {
    if (e.key === "Enter" && changeTitle) {
      const renamedFolder = {
        ...folderData[id],
        changeTitle: false,
        title: item,
      };
      setFolderData((folderData) => ({
        ...folderData,
        [id]: renamedFolder,
      }));
    }
  }

  useEffect(() => {
    document
      .querySelector(".WindowBodyContainer")
      .addEventListener("click", handleClick);
    document
      .querySelector(".WindowBodyContainer")
      .addEventListener("keypress", handleEnter);
    return () => {
      //when we go mobil the document with WindowBodyContainer disappear. it need to be tested
      if (document.querySelector(".WindowBodyContainer")) {
        document
          .querySelector(".WindowBodyContainer")
          .removeEventListener("click", handleClick);
        document
          .querySelector(".WindowBodyContainer")
          .removeEventListener("keypress", handleEnter);
      }
    };
  });
  //"images/folderEmpty.png"
  let folderClasses = 'folder'
  if (isSelected && !level.FolderHighlightId.includes(id)) {
    folderClasses = 'selected folder'
  } 
  else if(level.FolderHighlight && !isSelected && level.FolderHighlightId.includes(id)) {
    folderClasses = 'folder folderHighlight'
  }
  else if(isSelected && level.FolderHighlight && level.FolderHighlightId.includes(id)) {
    folderClasses = 'selected folder folderHighlight'
  }
  else if(isTargeted) {
    folderClasses = 'folder folderIsTargeted'
  }
  
  return (
    <div
      // className={
      //   isSelected
      //     ? "selected folder"
      //     : isTargeted
      //     ? "folder folderIsTargeted"
      //     : "folder"
      // }
      className={folderClasses}
      id={id}
      // animate={{ rotate: 360 }}
      // transition={{ duration: 2 }}
    >
      <img
        src={`images/${picture}`}
        alt="FolderPicture"
        // className="folderPicture"
        className={type === "folder" ? "folderPicture" : "filePicture"}
      />
      {changeTitle ? (
        <input
          type="text"
          onChange={handleChange}
          value={item}
          className="inputBox"
          autoFocus
          onFocus={handleFocus}
        />
      ) : (
        <span className="folderTitle">{title}</span>
      )}
    </div>
  );
};

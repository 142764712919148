import "./WindowsApp.css";
import { useState, useEffect, useContext } from "react";
import ReactGA from "react-ga";
import OneWindow from "./explorer/OneWindow";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useNavigate, useParams } from "react-router-dom";
import LevelContext from "../utils/Level";
import CurrentFolderContext from "../utils/CurrentFolder";
import FolderDataContext from "../utils/FolderData";
import LanguageContext from "../utils/Language";
import levelList from "../levels/levelList.json";
import { ContextMenu } from "./contextMenu/ContextMenu";
import WindowsBar from "./windowsBar/WindowsBar";
import { ControlBar } from "./ControlBar/ControlBar";
import { StatusBar } from "./ControlBar/StatusBar";

// import { LevelModal } from "./Modal/LevelModal";
import { SuccessModal } from "./Modal/SuccessModal";
import { InstructionWindow } from "./InstructionWindow/InstructionWindow";
import { FolderInitiator } from "./FolderInitiator";
import { LevelHandler } from "./Level/LevelHandler";

const initiationData = (levelFolderData) => {
  const data = FolderInitiator(levelFolderData);
  return {
    id: data.id,
    currentFolder: data.currentFolder,
    folderData: data.data,
  };
};

function WindowsApp({ user, setUser }) {
  let params = useParams();
  //console.log(levelList);
  const [level, setLevel] = useState({ levelId: 1 });
  const [restart, setRestart] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const { language, setLanguage } = useContext(LanguageContext);

  const LevelContextValue = {
    level,
    setLevel: setLevel,
  };

  const [actionFromMenu, setActionFromMenu] = useState({
    action: "idle",
    idClicked: "none",
    localX: 0,
    localY: 0,
  });

  let navigate = useNavigate();
  function redirectToContact() {
    navigate("/contact");
  }
  function redirectToCourseEnd() {
    navigate("/courseEnd");
  }
// || level.levelId < 5
  function closeSuccessModal(closeOrigin) {
    // if (user.isAuthenticated || level.levelId < 4 ) {
      if (closeOrigin && level.levelId < 24) {
        const nextLevelId = level.levelId + 1;
        setLevel((previous) => ({ ...previous, levelId: nextLevelId }));
        ReactGA.event({
          category: "WindowsApp",
          action: "ChangeOfLevel",
          label: `level ${level.levelId}`,
        });
      } else {
        setLevel((previous) => ({ ...previous, success: false }));
        redirectToCourseEnd();
      }
    // }
    // else {
    //   navigate("/CheckOutUser");
    // }
  }

  const [currentFolder, setCurrentFolder] = useState();
  const FolderPathContextValue = {
    currentFolder,
    setCurrentFolder: setCurrentFolder,
  };
  const [folderData, setFolderData] = useState();
  const FolderDataContextValue = {
    folderData,
    setFolderData: setFolderData,
  };

  const [initialData, setInitialData] = useState();
  //Load level data function
  const fetchData = async (levelIdToLoad) => {
    //${level.levelId}
    let { levelFolderData } = await import(
      `../levels/level${levelIdToLoad}/levelFolderData-${language}`
    );
    const { levelData } = await import(
      `../levels/level${levelIdToLoad}/levelData`
    );
    const { levelSuccessCriteria } = await import(
      `../levels/level${levelIdToLoad}/levelSuccessCriteria`
    );
    const { LevelInstruction } = await import(
      `../levels/level${levelIdToLoad}/LevelInstruction`
    );
    levelFolderData = initiationData(levelFolderData);
    setInitialData({
      levelFolderData,
      levelData,
      levelSuccessCriteria,
      LevelInstruction,
    });
    setLevel((previous) => ({ ...previous, ...levelData }));
    setCurrentFolder(levelFolderData.currentFolder);
    setFolderData(levelFolderData.folderData);
    setIsLoaded(true);
  };

  // load new level upon levelId change (next level or success)
  useEffect(() => {
    // const fetchDataLauncher = async (levelIdToLoad) => {
    //   await fetchData(levelIdToLoad);
    //   setIsLoaded(true);
    // };
    const levelIdToLoad = level.levelId;
    setIsLoaded(false);

    if (user.isAuthenticated || level.levelId < 25 ) {
      fetchData(levelIdToLoad);
    }
    else {
      navigate("/contact");
      // navigate("/CheckOutUser");
    }
    // fetchDataLauncher(levelIdToLoad);
    //fetchData(levelIdToLoad);
    //setIsLoaded(true);
  }, [level.levelId]);
  // re load level upon restart instruction from the control bar
  useEffect(() => {
    const levelIdToLoad = level.levelId;
    setIsLoaded(false);
    fetchData(levelIdToLoad);
    setRestart(false);
  }, [restart]);
  //Trial version handling:
  // useEffect(() => {
  //   //&& level.levelId < 5
  //   if (!user.isAuthenticated && level.success) {
  //     if (level.levelId > 4) {
  //       navigate("/CheckOutUser");
  //     }
  //     if (level.levelId < 4) {
  //       const nextLevelId = level.levelId + 1;
  //       setLevel((previous) => ({ ...previous, levelId: nextLevelId }));
  //     }
  //   }
  // }, [level.success, level.levelId]);

  //check url params,
  // useEffect(() => {
  //   const auth = async () => {
  //     const payload = {
  //       urlId: params.urlId,
  //     };
  //     // console.log(payload);
  //     try {
  //       // POST request using fetch with async/await
  //       const requestOptions = {
  //         method: "POST",
  //         headers: { "Content-Type": "application/json" },
  //         body: JSON.stringify(payload),
  //       };

  //       const response = await fetch(
  //         // "https://interactive-learning-be.herokuapp.com/api/user",
  //         "https://interactivelearningbe.herokuapp.com/api/auth/urlIdLogin",
  //         requestOptions
  //       );
  //       // console.log(response);
  //       const data = await response.json();
  //       // console.log(data);
  //       if (response.status === 200) {
  //         localStorage.setItem("login_access_token", data.accessToken);
  //         // console.log("login successful");
  //         setUser((prevState) => ({
  //           ...prevState,
  //           email: data.email,
  //           urlId: data.urlId,
  //           isAuthenticated: true,
  //         }));
  //         // console.log(data);
  //       }
  //       else if (response.status === 401) {
  //         //console.log("user is not authorized");
  //       } 
  //     } catch (error) {
  //       //console.log("user is not authorized");
  //     }
  //   };
  //   auth();
  // }, []);
  // console.log(isLoaded);
  return (
    isLoaded && (
      <DndProvider backend={HTML5Backend}>
        <LevelContext.Provider value={LevelContextValue}>
          <FolderDataContext.Provider value={FolderDataContextValue}>
            <CurrentFolderContext.Provider value={FolderPathContextValue}>
              <div className="App">
                {/* different scenario depending if user is authenticated or not */}
                {/* {user.isAuthenticated && ( */}
                  <SuccessModal
                    successModalStatus={level.success}
                    setSuccessModalStatus={closeSuccessModal}
                  />
                {/* )} */}
                <LevelHandler initialData={initialData} />
                <ControlBar
                  setRestart={setRestart}
                  levelList={levelList}
                  language={language}
                  user={user}
                  setUser={setUser}
                />
                {/* <StatusBar user={user} setUser={setUser} /> */}
                <div className="instructionAndWindowWrapper">
                  <InstructionWindow initialData={initialData} />
                  <OneWindow
                    actionFromMenu={actionFromMenu}
                    initialData={initialData}
                  />
                </div>
                <ContextMenu value={setActionFromMenu} />
                <WindowsBar />
              </div>
            </CurrentFolderContext.Provider>
          </FolderDataContext.Provider>
        </LevelContext.Provider>
      </DndProvider>
    )
  );
}

export default WindowsApp;

//import { memo } from "react";
import "./OverlayArray.css";
import FolderDataContext from "../../utils/FolderData";
import CurrentFolderContext from "../../utils/CurrentFolder";
import { useContext, useState, useEffect } from "react";
// import { motion } from "framer-motion";
import LevelContext from "../../utils/Level";
//export const Folder = memo(function Folder({ title, yellow, preview }) {
//});
function getStyles(left, top, width, angle) {
  const transform = `translate3d(${left}px, ${top}px, 0) rotate(${angle}deg)`;
  // const transform = `translate3d(${left}px, ${top}px, 0)`;
  const widthCss = `${width}px`;
  // rotate(${angle})
  // console.log(transform);
  return {
    position: "absolute",
    transform,
    WebkitTransform: transform,
    transformOrigin: "left 10px",
    width: widthCss,
    // IE fallback: hide the real node using CSS when dragging
    // because IE will ignore our custom "empty image" drag preview.
    //opacity: isDragging ? 0 : 1,
    //height: isDragging ? 0 : '',
  };
}

const angle = (originLeft, originTop, destinationLeft, destinationTop) => {
  var dx = originLeft - destinationLeft;
  var dy = originTop - destinationTop;
  var theta = Math.atan2(-dy, -dx); // [0, Ⲡ] then [-Ⲡ, 0]; clockwise; 0° = east
  theta *= 180 / Math.PI; // [0, 180] then [-180, 0]; clockwise; 0° = east
  if (theta < 0) theta += 360; // [0, 360]; clockwise; 0° = east
  return theta;
};

export const OverlayArray = ({ id }) => {
  const { folderData, setFolderData } = useContext(FolderDataContext);
  const { currentFolder, setCurrentFolder } = useContext(CurrentFolderContext);
  const { level, setLevel } = useContext(LevelContext);
  const [displayArray, setDisplayArray] = useState(true);
  // const [item, setItem] = useState(title);
  const originFolderId = level.FolderToFolderArray_direction[0];
  const destinationFolderId = level.FolderToFolderArray_direction[1];
  //folder size = about 115 px
  const folderDiagonal = 20;
  const originLeft = folderData[originFolderId].left + folderDiagonal + 14;
  const originTop = folderData[originFolderId].top + folderDiagonal;
  const destinationLeft =
    folderData[destinationFolderId].left + folderDiagonal + 20;
  const destinationTop = folderData[destinationFolderId].top + folderDiagonal;
  let divWidth =
    Math.floor(
      Math.sqrt(
        (destinationLeft - originLeft) ** 2 + (destinationTop - originTop) ** 2
      ) * 100
    ) / 100;
  //folder size = about 115 px
  // divWidth = divWidth - 115;
  const divAngle = angle(
    originLeft,
    originTop,
    destinationLeft,
    destinationTop
  );

  // console.log(divAngle);

  useEffect(() => {
    const originFolderId = level.FolderToFolderArray_direction[0];
    const destinationFolderId = level.FolderToFolderArray_direction[1];
    const haveSameParent =
      folderData[originFolderId].parentId ===
      folderData[destinationFolderId].parentId;
      // console.log(haveSameParent);
      // console.log(folderData[originFolderId].parentId);
      // console.log(folderData[destinationFolderId].parentId);
      //check as well that we are currently in the 'folders' directory
      const userIsInTheDirectory = 
      folderData[originFolderId].parentId ===
      currentFolder;

    if (haveSameParent && userIsInTheDirectory) {
      setDisplayArray(true)
    } 
    else {
      setDisplayArray(false)
    };
  }, [folderData,currentFolder]);
  return (
    <div
      id="arrowAnim"
      style={getStyles(originLeft, originTop, divWidth, divAngle)}
    >
      {displayArray && <div className="arrowSliding">
        <div className="arrow"></div>
      </div>}
      {/* {displayArray && <div className="arrowSliding delay1">
        <div className="arrow"></div>
      </div>} */}
      {/* <div>My text here</div> */}
    </div>
  );
};

import "./ContextMenu.css";
import { useContext } from "react";

import { FormattedMessage } from "react-intl";
import LevelContext from "../../utils/Level";


export const NewContextMenu = (props) => {
  const { level, setLevel } = useContext(LevelContext);
  return (
    <div
      className="subMenu-container"
      style={{ top: props.yPos, left: props.xPos, position: "absolute" }}
    >
      {/* The second class is used for action detection: here addFolder */}
      <div
        className="menu"
        onMouseEnter={props.handleHoverInsideSubContext}
        onMouseLeave={props.handleHoverInsideSubContext}
      >
        <div 
        // className="contextMenuAddFolderDiv addFolder context_New_animationText"
        className={`contextMenuAddFolderDiv addFolder ${
            level.NewContextMenuFolder && "context_animationText"
          }`}
        >
          <img
            src="images/folderEmpty.png"
            alt="contextMenuAddFolderPicture"
            className="contextMenuAddFolderPicture addFolder"
          />
          <span className="dummyClass addFolder">
            <FormattedMessage
              id="NewContextMenu.span.Folder"
              defaultMessage="Folder"
            />
          </span>
        </div>
        <span className="subMenuItem notAvailableOption">
          <FormattedMessage
            id="NewContextMenu.span.Shortcut"
            defaultMessage="Shortcut"
          />
        </span>
        <div className="menuLine"></div>
        <span className="subMenuItem notAvailableOption">
          <FormattedMessage
            id="NewContextMenu.span.Image"
            defaultMessage="Bitmap image"
          />
        </span>
        <span className="subMenuItem notAvailableOption">
          <FormattedMessage
            id="NewContextMenu.span.Text"
            defaultMessage="Text Document"
          />
        </span>
        <span className="subMenuItem notAvailableOption">
          <FormattedMessage
            id="NewContextMenu.span.Zip"
            defaultMessage="Compressed (zipped) Folder"
          />
        </span>
      </div>
    </div>
  );
};

import "./ContextMenu.css";
import { FormattedMessage } from "react-intl";


export const SendToContextMenu = (props) => {
  return (
    <div
      className="subMenu-container"
      style={{ top: props.yPos, left: props.xPos, position: "absolute" }}
    >
      <div
        className="menu"
        onMouseEnter={props.handleHoverInsideSubContext}
        onMouseLeave={props.handleHoverInsideSubContext}
      >
        <span className="menuItem notAvailableOption">
        <FormattedMessage
            id="SendToContextMenu.span.Desktop"
            defaultMessage="Desktop"
          />
        </span>
        <span className="menuItem notAvailableOption">
        <FormattedMessage
            id="SendToContextMenu.span.Zip"
            defaultMessage="Create A Compressed (zipped) Folder"
          />
        </span>
        {/* <div className="menuLine"></div>
        <span className="menuItem notAvailableOption">Bitmap image</span>
        <span className="menuItem notAvailableOption">Text Document</span>
        <span className="menuItem notAvailableOption">Compressed Zip Folder</span> */}
      </div>
    </div>
  );
};

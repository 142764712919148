import { useState, useContext } from "react";
import "./ContextMenu.css";
import { NewContextMenu } from "./NewContextMenu";
import { FormattedMessage } from "react-intl";
import LevelContext from "../../utils/Level";

export const EmptyAreaContextMenu = (props) => {
  const [showSubContext, setShowSubContext] = useState(false);
  const { level, setLevel } = useContext(LevelContext);

  const handleHover = () => {
    setShowSubContext((previous) => !previous);
  };
  const handleHoverInsideSubContext = () => {
    setShowSubContext((previous) => !previous);
  };
  // const subYPos = props.yPos;
  // const subXPos = props.xPos;

  return (
    <div
      className="menu-container"
      style={{ top: props.yPos, left: props.xPos, position: "absolute" }}
    >
      <div className="menu">
        <span className="menuItem notAvailableOption">
          <FormattedMessage
            id="EmptyAreaContextMenu.span.View"
            defaultMessage="View"
          />
        </span>
        <span className="menuItem notAvailableOption">
          <FormattedMessage
            id="EmptyAreaContextMenu.span.Sort"
            defaultMessage="Sort by"
          />
        </span>
        <span className="menuItem notAvailableOption">
          <FormattedMessage
            id="EmptyAreaContextMenu.span.Group"
            defaultMessage="Group by"
          />
        </span>
        <span className="menuItem notAvailableOption">
          <FormattedMessage
            id="EmptyAreaContextMenu.span.Refresh"
            defaultMessage="Refresh"
          />
        </span>
        <div className="menuLine"></div>
        <span className="menuItem notAvailableOption">
          <FormattedMessage
            id="EmptyAreaContextMenu.span.Customize"
            defaultMessage="Customize this folder..."
          />
        </span>
        <div className="menuLine"></div>
        <span 
        // className="menuItem paste"
        className={`menuItem paste ${
            level.FolderContextMenuPaste && "context_animationText"
          }`}>
          <FormattedMessage
            id="EmptyAreaContextMenu.span.Paste"
            defaultMessage="Paste"
          />
        </span>
        <span className="menuItem notAvailableOption">
          <FormattedMessage
            id="EmptyAreaContextMenu.span.Paste.Shortcut"
            defaultMessage="Paste shortcut"
          />
        </span>
        <span className="menuItem notAvailableOption">
          <FormattedMessage
            id="EmptyAreaContextMenu.span.Undo"
            defaultMessage="Undo"
          />
        </span>
        <div className="menuLine"></div>
        <span className="menuItem notAvailableOption">
          <FormattedMessage
            id="EmptyAreaContextMenu.span.Access"
            defaultMessage="Give access to"
          />
        </span>
        <div className="menuLine"></div>
        {/* <span className={ props.helper.display
              ? "menuItem contextMenuAnimate"
              : "menuItem"}
              onMouseEnter={handleHover}
              onMouseLeave={handleHover}>
          New {">"}
          {props.helper.display && (
            <span className="contextMenuHelpText">{props.helper.message}</span>
          )}
        </span> */}
        <span
          onMouseEnter={handleHover}
          onMouseLeave={handleHover}
          // className="menuItem context_animationText"
          className={`menuItem newSubMenu ${
            level.EmptyAreaContextMenuNew && "context_animationText"
          }`}
        >
          <FormattedMessage
            id="EmptyAreaContextMenu.span.New"
            defaultMessage="New >"
          />
        </span>
        {/* reference to parent context menu , X shift of 10 rem - 160px */}
        {showSubContext && (
          <NewContextMenu
            yPos={100}
            xPos={197}
            helper={props.helper}
            handleHoverInsideSubContext={handleHoverInsideSubContext}
          />
        )}
        <div className="menuLine"></div>
        <span className="menuItem notAvailableOption">
          <FormattedMessage
            id="EmptyAreaContextMenu.span.Properties"
            defaultMessage="Properties"
          />
        </span>
      </div>
    </div>
  );
};

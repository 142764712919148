import "./WindowsBar.css";
import { useState, useEffect,useContext } from "react";
import Modal from "react-modal";
import LanguageContext from "../../utils/Language"


// Modal.setAppElement("#root");

function WindowsBar() {
  const { language, setLanguage } = useContext(LanguageContext);
  const [dateState, setDateState] = useState(new Date());
  useEffect(() => {
    const timer = setInterval(() => setDateState(new Date()), 10000); 
    return () => clearInterval(timer); // clearing interval
  }, []);

  //Modal
  // let subtitle;
  // const [modalIsOpen, setIsOpen] = useState(false);

  // function openModal() {
  //   setIsOpen(true);
  // }

  // function closeModal() {
  //   setIsOpen(false);
  // }
  //End modal

  return (
    <div className="WindowsBar">
      <div className="WindowsBarStartBox">
        <img
          className="windowsBarStartButton barButton"
          src="images/svg/windows.svg"
          alt="windowsBarStartButton"
          // onClick={openModal}
        />
        {/* <Modal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <h2 className='modalText'>Hello let's START you journey on Learn Windows</h2>
          <button onClick={closeModal}>close</button>
        </Modal> */}

        <img
          className="windowsSearchButton barButton"
          src="images/svg/search.svg"
          alt="windowsSearchButton"
        />
        <img
          className="windowsCortanaButton barButton"
          src="images/svg/circle.svg"
          alt="windowsCortanaButton"
        />
      </div>

      <div className="WindowsBarTaskBox">
        <div className="WindowsBarTaskBoxContainer">
          <img
            className="windowsTaskViewButton WindowsBarTaskBoxButton"
            src="images/svg/task-view.svg"
            alt="windowsTaskViewButton"
          />
        </div>
        <div className="WindowsBarTaskBoxContainer">
          <img
            className="windowsChromeButton WindowsBarTaskBoxButton"
            src="images/svg/chrome.svg"
            alt="windowsChromeButton"
          />
        </div>
        <div className="WindowsBarTaskBoxContainer">
          <img
            className="WindowsBarTaskBoxButton windowsExplorerButton"
            src="images/file-explorer.png"
            alt="windowsExplorerButton"
          />
        </div>
      </div>

      <div className="WindowsBarSystemBox">
        <img
          className="windowsChevronUpButton WindowsBarSystemBoxButton"
          src="images/svg/chevronUp.svg"
          alt="windowsChevronUpButton"
        />
        <img
          className="windowsBatteryButton WindowsBarSystemBoxButton"
          src="images/svg/battery.svg"
          alt="windowsBatteryButton"
        />
        <img
          className="windowsWifiButton WindowsBarSystemBoxButton"
          src="images/svg/wifi.svg"
          alt="windowsWifiButton"
        />
        <img
          className="windowsBluetoothButton WindowsBarSystemBoxButton"
          src="images/svg/bluetooth.svg"
          alt="windowsBluetoothButton"
        />
        <img
          className="windowsSpeakerButton WindowsBarSystemBoxButton"
          src="images/svg/speaker-high.svg"
          alt="windowsSpeakerButton"
        />
        <span className="WindowsBarSystemBoxText">{language === 'en' ? "EN" : "FRA"}</span>
        <div className="WindowsBarSystemBoxHourDate">
          <span className="WindowsBarSystemBoxText">
            {dateState.toLocaleString("en-US", {
              hour: "numeric",
              minute: "numeric",
              hour12: false,
            })}
          </span>
          <span className="WindowsBarSystemBoxText">
            {dateState.toLocaleDateString("en-GB", {
              day: "numeric",
              month: "numeric",
              year: "numeric",
            })}
          </span>
        </div>
        <img
          className="windowsMessageButton WindowsBarSystemBoxButton"
          src="images/svg/message.svg"
          alt="windowsMessageButton"
        />
      </div>
    </div>
  );
}

export default WindowsBar;

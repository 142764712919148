import "./ControlBar.css";
import { useContext } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
// import LanguageContext from "../../utils/Language";
import LevelContext from "../../utils/Level";
import Language from "../../utils/Language";
import { StatusBar } from "./StatusBar";

export const ControlBarLanding = (props) => {
  // console.log(props);
  const { level, setLevel } = useContext(LevelContext);
  let navigate = useNavigate();

  function redirectToContact() {
    navigate("/contact");
  }
  function redirectToHome() {
    navigate("/");
  }



  return (
    <div className="ControlBar_frontLanding">
      <div className="StatusBar_Logo" onClick={redirectToHome}>
        WP
      </div>
      <StatusBar user={props.user} setUser={props.setUser} />
    </div>
  );
};

import "./WindowBody.css";
import CurrentFolderContext from "../../utils/CurrentFolder";
import FolderDataContext from "../../utils/FolderData";
import LevelContext from "../../utils/Level";
import { DraggableFolder } from "./DraggableFolder";
import { OverlayArray } from "./OverlayArray";
import { useDrop } from "react-dnd";
import { ItemTypes } from "../../utils/items";
import { useCallback, useContext, useEffect, useState } from "react";
//import update from "immutability-helper";
// import {ContextMenu} from './ContextMenu'
import SelectionArea, { SelectionEvent } from "@viselect/react";

function WindowBody() {
  const { currentFolder, setCurrentFolder } = useContext(CurrentFolderContext);
  const { folderData, setFolderData } = useContext(FolderDataContext);
  const [helper, setHelper] = useState({
    display: false,
    message: "",
    id: "",
  });
  const { level, setLevel } = useContext(LevelContext);
  const [selected, setSelected] = useState(() => new Set());
  // console.log(level);
  //on change of folder, reset the selected
  useEffect(() => {
    setSelected(() => new Set());
  }, [currentFolder]);

  const extractIds = (els) =>
    els
      .map((v) => v.getAttribute("data-key"))
      .filter(Boolean)
      .map(Number);

  const onStart = ({ event, selection }) => {
    // console.log(selected);
    if (!event?.ctrlKey && !event?.metaKey) {
      selection.clearSelection();
      setSelected(() => new Set());
    }
  };
  const onBeforeStart = ({ event, selection }) => {
    //For Selection, cancel selection square animation if click on folder but still add the folder id to the set
    const folderId = parseFloat(
      event.target.parentElement.attributes.id.textContent
    );
    // console.log(event.target.className);
    const notClickOnFolder =
      event.target.className !== "folder" &&
      event.target.className !== "selected folder" &&
      event.target.className !== "folderPicture" &&
      event.target.className !== "filePicture" &&
      event.target.className !== "folderTitle";
    //handle the case to identify if a folder clicked is already in the selection, like to initiate a dragging
    //in this case do not change anything
    //if the folder clicked is not within the selection, it is a cancel of current selection
    //the start a new set and perhaps initiate dragging
    //if click on a folder and this folder is not already in the selection:
    // console.log(notClickOnFolder);
    setSelected((prev) => {
      if (!notClickOnFolder && !prev.has(folderId)) {
        const next = new Set();
        next.add(folderId);
        // console.log(next);
        return next;
      } else if (notClickOnFolder) {
        const next = new Set();
        return next;
      } else {
        return prev;
      }
    });
    return notClickOnFolder;
  };
  const onMove = ({
    store: {
      changed: { added, removed },
    },
  }) => {
    setSelected((prev) => {
      const next = new Set(prev);
      extractIds(added).forEach((id) => next.add(id));
      extractIds(removed).forEach((id) => next.delete(id));
      return next;
    });
  };
  const moveFolder = useCallback(
    (selectedCards, delta) => {
      setFolderData((prev) => {
        let next = {
          ...prev,
        };
        for (const card of selectedCards) {
          const id = card;
          let left = Math.round(folderData[id].left + delta.x);
          let top = Math.round(folderData[id].top + delta.y);
          const updatedCurrentFolder = {
            ...folderData[id],
            top: top,
            left: left,
          };
          next = {
            ...next,
            [id]: updatedCurrentFolder,
          };
        }

        return next;
      });
    },
    [folderData]
  );

  const [{ isOverCurrent }, drop] = useDrop(
    () => ({
      accept: [ItemTypes.FOLDER, ItemTypes.FILE],
      drop(item, monitor) {
        //did drop checked the folder has not been dropped in another drop area (a folder for example)
        const didDrop = monitor.didDrop();
        // const notOverChild = monitor.isOver({ shallow: true });
        // const notOverChild2 = monitor.isOver();
        //do a check on x:y position to avoid file in the current folder...
        // console.log(`is over child : ${notOverChild}`);
        if (!didDrop) {
          const delta = monitor.getDifferenceFromInitialOffset();
          moveFolder(item.selectedCards, delta);
          return undefined;
        }
      },
    }),
    [moveFolder]
  );
  const preventDefault = (e) => e.preventDefault(); //to prevent firefox opening the folder picture on drop
  //https://github.com/react-dnd/react-dnd/issues/3179
  //console.log(currentFolder);
  //      <div ref={drop} className="WindowBodyDragZone" id="WindowBodyDragZone" onDrop={preventDefault}>

  //Check overlay arrow conditionnal display:

  return (
    <div className="WindowBodyContainer" id="windowZone">
      {level.windowBodyZoneHighLight && (
        <div className="windowBody_zoneHighLight"></div>
      )}
      {level.FolderToFolderArray && (
        <div className="windowBody_FolderToFolderArrayContainer">
          <OverlayArray />
        </div>
      )}
      <div
        ref={drop}
        className="WindowBodyDragZone"
        id="WindowBodyDragZone"
        onDrop={preventDefault}
      >
        <SelectionArea
          className="container"
          onStart={onStart}
          onMove={onMove}
          onBeforeStart={onBeforeStart}
          selectables=".selectable"
        >
          {Object.keys(folderData).map((key) => {
            //console.log(folderData[currentFolder].childrenId)
            return (
              folderData[currentFolder].childrenId.includes(
                parseFloat(key)
              ) && (
                <DraggableFolder
                  key={key}
                  id={key}
                  selectedCards={[...selected]}
                  isSelected={selected.has(parseFloat(key))}
                  {...folderData[key]}
                  value={folderData[key].title}
                />
              )
            );
          })}
        </SelectionArea>
      </div>
      {/* <ContextMenu/> */}
    </div>
  );
}

export default WindowBody;

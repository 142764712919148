import "./WindowHeader.css";
import { useContext } from "react";
import CurrentFolderContext from "../../utils/CurrentFolder";
import FolderDataContext from "../../utils/FolderData";

function WindowHeader() {
  const { currentFolder, setCurrentFolder } = useContext(CurrentFolderContext);
  const { folderData, setFolderData } = useContext(FolderDataContext);

  return (
    <div className="WindowHeader">
      <img
        src={`images/${folderData[currentFolder].picture}`}
        alt="HeaderFileExplorerPicture"
        className="HeaderFileExplorerPicture"
      />
      <span className="HeaderBar">|</span>
      <span className="HeaderTitle">{folderData[currentFolder].title}</span>
      <div className="HeaderControlBox">
        <img
          className="headerMinimizeButton HeaderControlButton"
          src="images/svg/minus.svg"
          alt="headerMinimizeButton"
        />
        <img
          className="headerMaximizeButton HeaderControlButton"
          src="images/svg/square.svg"
          alt="headerMaximizeButton"
        />
        <img
          className="headerCloseButton HeaderControlButton"
          src="images/svg/close.svg"
          alt="headerCloseButton"
        />
      </div>
    </div>
  );
}

export default WindowHeader;

import useMediaQuery from "./utils/useMediaQuery";
import WindowsApp from "./components/WindowsApp";
import MobileApp from "./components/MobileApp/MobileApp";
// import Legal from "./components/Legal/Legal";
import { ContactPage } from "./components/ContactPage/ContactPage";
import FAQ from "./components/FAQ/FAQ";
import { CourseEndPage } from "./components/ContactPage/CourseEndPage";
import { LandingDesktop } from "./components/LandingDesktop/LandingDesktop";
import { IntlProvider } from "react-intl";
import localeData from "./lang/languages.json";
import LanguageContext from "./utils/Language";
import { useEffect, useState } from "react";
// import ReactGA from "react-ga";
import { BrowserRouter, Routes, Route} from "react-router-dom";
// import { LogIn } from "./components/Auth/Login";
//commented the below line and 118 to work offline as well as auth set to true
// import { CheckOut } from "./components/Auth/CheckOut";
// import { PaymentSuccess } from "./components/Auth/PaymentSuccess";

// nvm use default 16.14.2 - updated to node16 in vercel dep, valid unti fev 2024

function App() {
  // ReactGA.initialize("UA-208616152-1");
  // ReactGA.pageview(window.location.pathname + window.location.search);

  const isDesktop = useMediaQuery("(min-width: 960px)");
  const [language, setLanguage] = useState("fr");
  const [messages, setMessages] = useState(localeData.fr);
  const LanguageContextValue = {
    language,
    setLanguage: setLanguage,
  };
  useEffect(() => {
    // Define user's language. Different browsers have the user locale defined
    // on different fields on the `navigator` object, so we make sure to account
    // for these different by checking all of them
    const local =
      (navigator.languages && navigator.languages[0]) ||
      navigator.language ||
      navigator.userLanguage;
    // Split locales with a region code
    const localWithoutRegionCode = local.toLowerCase().split(/[_-]+/)[0];
    // Try full locale, try locale without region code, fallback to 'en'
    //const messages = localeData[localWithoutRegionCode] || localeData[local] || localeData.en;
    if (localWithoutRegionCode !== "fr") {
      setLanguage("en");
      setMessages(localeData.en);
    }
  }, []);

  useEffect(() => {
    if (language === "en") {
      setMessages(localeData.en);
    }
    if (language === "fr") {
      setMessages(localeData.fr);
    }
  }, [language]);
  //change to true when offline
  const [user, setUser] = useState({
    email: "",
    urlId: "",
    isAuthenticated: false,
    level: "1",
  });
  
  //check if JWT is valid
  // useEffect(() => {
  //   const checkUserJWT = async () => {
  //     try {
  //       // POST request using fetch with async/await
  //       const requestOptions = {
  //         method: "GET",
  //         headers: {
  //           "Content-Type": "application/json",
  //           token: localStorage.getItem("login_access_token"),
  //         },
  //         // body: JSON.stringify(payload),
  //       };
  //       // console.log(requestOptions);
  //       const response = await fetch(
  //         "https://interactivelearningbe.herokuapp.com/api/users/verifyUserToken",
  //         requestOptions
  //       );
  //       // console.log(response);
  //       const data = await response.json();
  //       // console.log(data);
  //       if (response.status === 200) {
  //         setUser((prevState) => ({
  //           ...prevState,
  //           email: data.email,
  //           urlId: data.urlId,
  //           isAuthenticated: true,
  //         }));
  //         //console.log("valid user JWT");
  //       } else if (response.status === 401) {
  //         //console.log("user is not authorized");
  //       } 
  //       // else if (response.status === 403) {
  //       //   //console.log(response.statusText);
  //       // }
  //     } catch (error) {
  //       //console.log("Token check: user is not authorized");
  //     }
  //   };
  //   checkUserJWT();
  // }, []);
  // console.log(user);
  return (
    <IntlProvider locale={language} messages={messages}>
      {/* <IntlProvider locale={'en'} messages={messages}> */}
      <LanguageContext.Provider value={LanguageContextValue}>
        <BrowserRouter>
        {/* change to HashRouter instead of BrowserRouter if you want to combine it with express in the case of a be fe combined deployment  */}
        {/* <HashRouter> */}
          <Routes>
            {isDesktop && (
              <Route path="/" element={<LandingDesktop user={user} setUser={setUser} />} />
            )}
            {!isDesktop && <Route path="/" element={<MobileApp />} />}
            <Route
              path="/app/:urlId"
              element={<WindowsApp user={user} setUser={setUser} />}
            />
            {/* to handle unknown route , redirect to contact */}
            <Route path='/*' element={<ContactPage />} />

            <Route path="/contact" element={<ContactPage />} />
            <Route path="/faq" element={<FAQ />} />
            {/* <Route path="/legal" element={<Legal />} /> */}
            <Route path="/courseEnd" element={<CourseEndPage />} />
            {/* <Route path="/logInUser" element={<LogIn setUser={setUser} />} /> */}
            {/* <Route path="/CheckOutUser" element={<CheckOut />} /> */}
            {/* <Route
              path="/PaymentSuccess"
              element={<PaymentSuccess setUser={setUser} />}
            /> */}
          </Routes>
        </BrowserRouter>
        {/* </HashRouter> */}
      </LanguageContext.Provider>
    </IntlProvider>
  );
}

export default App;

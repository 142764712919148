import "./ContextMenu.css";
import { useState,useContext } from "react";
import { SendToContextMenu } from "./SendToContextMenu";
import { FormattedMessage } from "react-intl";
import LevelContext from "../../utils/Level";

export const FolderContextMenu = (props) => {
  const [showSubContext, setShowSubContext] = useState(false);
  const { level, setLevel } = useContext(LevelContext);
  const handleHover = () => {
    setShowSubContext((previous) => !previous)
  }
  const handleHoverInsideSubContext = () => {
    setShowSubContext((previous) => !previous)
  }
  return (
    <div
      className="menu-container"
      style={{ top: props.yPos, left: props.xPos, position: "absolute",}}>

      <div className="menu">
        <span className="menuItem open">
        <FormattedMessage
          id="FolderContextMenu.span.Open"
          defaultMessage="<b>Open</b>"
          values={{
            b: (chunks) => (
                <b>{chunks}</b>
            ),
          }}
        />
        </span>
        <div className="menuLine"></div>
        {/* <span className={ props.helper.display
              ? "menuItem notAvailableOption contextMenuAnimate"
              : "menuItem notAvailableOption"}
              onMouseEnter={handleHover}
              onMouseLeave={handleHover}>
          Send to {">"}
          {props.helper.display && (
            <span className="contextMenuHelpText">{props.helper.message}</span>
          )}
        </span> */}
        <span
          onMouseEnter={handleHover}
          onMouseLeave={handleHover}
          className="menuItem notAvailableOption"
        >
          <FormattedMessage
            id="FolderContextMenu.span.SendTo"
            defaultMessage="Send to >"
          />
        </span>
        {showSubContext && <SendToContextMenu yPos={23} xPos={197} helper={props.helper} handleHoverInsideSubContext={handleHoverInsideSubContext}/>}
        <div className="menuLine"></div>
        <span className="menuItem notAvailableOption">
        <FormattedMessage
            id="FolderContextMenu.span.Cut"
            defaultMessage="Cut"
          />
        </span>
        <span 
        // className="menuItem copy"
        className={`menuItem copy ${
            level.FolderContextMenuCopy && "context_animationText"
          }`}>
        <FormattedMessage
            id="FolderContextMenu.span.Copy"
            defaultMessage="Copy"
          />
        </span>
        <div className="menuLine"></div>
        <span className="menuItem notAvailableOption">
        <FormattedMessage
            id="FolderContextMenu.span.CreateShortcut"
            defaultMessage="Create shortcut"
          />
        </span>
        <span 
        // className="menuItem delete"
        className={`menuItem delete ${
            level.FolderContextMenuDelete && "context_animationText"
          }`}
        >
        <FormattedMessage
            id="FolderContextMenu.span.Delete"
            defaultMessage="Delete"
          />
        </span>
        <span 
        className={`menuItem rename ${
            level.FolderContextMenuRename && "context_animationText"
          }`}
        >
        <FormattedMessage
            id="FolderContextMenu.span.Rename"
            defaultMessage="Rename"
          />
        </span>
        <div className="menuLine"></div>
        <span className="menuItem notAvailableOption">
        <FormattedMessage
            id="FolderContextMenu.span.Properties"
            defaultMessage="Properties"
          />
        </span>
      </div>
    </div>
  );
};

//import { memo } from 'react';
import { useDrag, useDrop } from "react-dnd";
import { ItemTypes } from "../../utils/items";
import { Folder } from "./Folder";
import { useContext, useRef, useState, useLayoutEffect } from "react";
import CurrentFolderContext from "../../utils/CurrentFolder";
import FolderDataContext from "../../utils/FolderData";

function getStyles(left, top, isDragging) {
  const transform = `translate3d(${left}px, ${top}px, 0)`;
  return {
    position: "absolute",
    transform,
    WebkitTransform: transform,
    // IE fallback: hide the real node using CSS when dragging
    // because IE will ignore our custom "empty image" drag preview.
    //opacity: isDragging ? 0 : 1,
    //height: isDragging ? 0 : '',
  };
}
//export const DraggableFolder = memo(function DraggableFolder(props) {
//}); //at the end
export const DraggableFolder = function DraggableFolder(props) {
  //console.log('inDraggable');
  const ref = useRef(null);
  const { id,type, title,picture, changeTitle, left, top, selectedCards, isSelected } =
    props;
  const { currentFolder, setCurrentFolder } = useContext(CurrentFolderContext);
  const { folderData, setFolderData } = useContext(FolderDataContext);
  const [hasDropped, setHasDropped] = useState(false);
  //console.log(`${id}: ${changeTitle}`);

  function changePath(e) {
    //if the user is within a rename operation, cancel the custom double click to go inside a folder
    const event = e.target.className;
    //console.log(e.target.className);
    if (event !== "inputBox" && type ==='folder') {
      setCurrentFolder(folderData[id].id);
    }
  }

  const [{ isDragging }, drag] = useDrag(
    () => ({
      type: ItemTypes.FOLDER,
      canDrag: !changeTitle,
      item: { id, left, top, title, selectedCards },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [id, left, top, title, changeTitle,selectedCards]
  );

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: [ItemTypes.FOLDER,ItemTypes.FILE],
      drop(item, monitor) {
        //get drag folder id: item.id
        //get dropped over folder id : id
        //remove dragged folder id from parent children array
        //avoid self drop
        if (id !== item.id) {
          let originUpdatedChildrenId = folderData[currentFolder].childrenId;
          originUpdatedChildrenId = originUpdatedChildrenId.filter(
            (data) => data !== item.id
          );
          const originFolder = {
            ...folderData[currentFolder],
            childrenId: originUpdatedChildrenId,
          };
          //add dragged folder id to dropped folder children array
          let destinationUpdatedChildrenId = folderData[id].childrenId;
          destinationUpdatedChildrenId.push(item.id);
          const destinationFolder = {
            ...folderData[id],
            childrenId: destinationUpdatedChildrenId,
          };
          //change dragged folder parent id to drop folder id
          const movedFolder = {
            ...folderData[item.id],
            parentId: folderData[id].id,
          };
          //update folderData state
          setFolderData((folderData) => ({
            ...folderData,
            [currentFolder]: originFolder,
            [id]: destinationFolder,
            [item.id]: movedFolder,
          }));
        }
        //return undefined;
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
      }),
    }),
    [folderData]
  );

  //Difference a folder and a file as a file cannot be a drop zone
  type === 'folder' ? drag(drop(ref)) : drag(ref)

  // drag(drop(ref));
  //ref={drag}
  //role="DraggableFolder"
  //but on text selection with firefox,element is set to canDrag even if its with canDrag false
  //https://github.com/react-dnd/react-dnd/issues/2690
  useLayoutEffect(() => {
    if (ref.current) {
      if (changeTitle) {
        ref.current.setAttribute("draggable", "false");
      } else {
        ref.current.setAttribute("draggable", "true");
      }
    }
  }, [changeTitle, ref.current]);
  
  return (
    <div
      ref={ref}
      id={id}
      onDoubleClick={changePath}
      style={getStyles(left, top, isDragging)}
      className="selectable"
      data-key={props.id}
    >
      <Folder
        id={id}
        type={type}
        title={title}
        picture={picture}
        changeTitle={changeTitle}
        isSelected={isSelected}
        isTargeted={isOver}
      />
    </div>
  );
};

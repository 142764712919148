import "./MobileApp.css";
import { FormattedMessage } from "react-intl";
import LanguageContext from "../../utils/Language";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";




function MobileApp() {
  const { language, setLanguage } = useContext(LanguageContext);
  let navigate = useNavigate();
  const redirectToFAQ = () => {
    // props.updateTitle("Home");
    navigate(`/faq`);
  };
  //Pour choisir entre la version FR et EN de la vidéo
  return (
    <div className="mobileApp">
      <div className="mobileApp_container">
        <span className="mobileApp_Logo">WindowsProfessor.com</span>
        <h1 className="mobileApp_text">
          <FormattedMessage
            id="MobileApp.h1.message"
            defaultMessage="Mastering Windows has never been easier."
          />
        </h1>
        <div className="mobileApp_intermediateContainer">
          <h2 className="mobileApp_H2">
            <FormattedMessage
              id="MobileApp.h2.message1"
              defaultMessage="Learn with "
            />
            <span className="mobileApp_gradientText">
            <FormattedMessage
              id="MobileApp.span.message2"
              defaultMessage="interactive exercises"
            />
            </span>
          </h2>
          <video
            className="mobileApp_gifDemo"
            title="WindowsProfessor.com demo"
            loading="lazy"
            autoPlay
            muted
            loop
          >
            <source src="images/Mobile.mp4" type="video/mp4" />
          </video>
          <h3 className="mobileApp_H3">
            <FormattedMessage
              id="MobileApp.h3.message"
              defaultMessage="Come back on your computer to test the exercises 💻"
            />
          </h3>
        </div>
        <div
              className="LandingDesktop_Button LandingDesktop_Button_small"
              onClick={() => redirectToFAQ()}
            >
              <span className="LandingDesktop_buttonText">
                <FormattedMessage
                  id="LandingDesktop.span.FAQ"
                  defaultMessage="Frequently Asked Questions"
                />
              </span>
            </div>
        <span className="mobileApp_connectText">
          <FormattedMessage
            id="MobileApp.span.Twitter.1"
            defaultMessage="Want to know more? "
          />
          <a href="https://twitter.com/indie_CL">
            <FormattedMessage
              id="MobileApp.a.Twitter.2"
              defaultMessage="connect on Twitter 😊"
            />
          </a>
        </span>
        <span className="mobileApp_copyright">
        WindowsProfessor.com © 2023 - 2024
        </span>
        <div className="mobileApp_languageContainer">
        <img
          onClick={()=> {setLanguage('en')}}
          src="images/english.png"
          alt="english language icon"
          className="mobileApp_language_Button"
        />
        <img
          onClick={()=> {setLanguage('fr')}}
          src="images/french.png"
          alt="English language icon"
          className="mobileApp_language_Button"
        />
      </div>
      </div>
    </div>
  );
}

export default MobileApp;

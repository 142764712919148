import { useState, useEffect } from "react";

export const useContextMenu = () => {
  const [xPos, setXPos] = useState("0px");
  const [yPos, setYPos] = useState("0px");
  const [showMenu, setShowMenu] = useState(false);
  const [action, setAction] = useState("idle");
  const [idClicked, setIdClicked] = useState("none");
  const clickOption = [
    "addFolder",
    "rename",
    "delete",
    "copy",
    "paste",
    "open",
    "notAvailableOption",
  ];
  const clickOptionKeepOpen = ["newSubMenu"];

  function handleContextMenu(e) {
    setIdClicked(e.srcElement.offsetParent.id);
    e.preventDefault();
    setXPos(`${e.pageX}px`);
    setYPos(`${e.pageY}px`);
    setShowMenu(true);
  }
  //if click on context menu line that open a sub menu, context should not be closed => if clickOptionKeepOpen.
  function handleClick(e) {
    const event = e.srcElement.classList[1];
    // console.log(e.srcElement.classList);
    if (!clickOptionKeepOpen.includes(event)) {
      showMenu && setShowMenu(false);
      if (clickOption.includes(event)) {
        // console.log(event);
        setAction(event);
        setAction("idle");
        setIdClicked("none");
      }
    }
  }

  useEffect(() => {
    document.addEventListener("click", handleClick);
    document
      .querySelector(".WindowBodyContainer")
      .addEventListener("contextmenu", handleContextMenu);
    //console.log(showMenu);
    return () => {
      document.removeEventListener("click", handleClick);
      //when we go mobil the document with WindowBodyContainer disappear. it need to be tested
      if (document.querySelector(".WindowBodyContainer")) {
        document
          .querySelector(".WindowBodyContainer")
          .removeEventListener("contextmenu", handleContextMenu);
      }
    };
  });

  return { xPos, yPos, showMenu, action, idClicked };
};

export const FolderMaker = (id, type, title, picture, parentId, childrenId, top, left) => {
  const folder = {
    id,
    type,
    title,
    picture,
    parentId,
    childrenId, //array with children id
    top,
    left,
    changeTitle: false
  }
  return folder
}


export const FolderInitiator = (levelFolderData) => {
  let data = {};
  for (const folder in levelFolderData) {
    const key = levelFolderData[folder][0];
    const dataTemp = FolderMaker(...levelFolderData[folder])
    const newKeyPair = {
      [key]: dataTemp
    }
    data = {
      ...data,
      ...newKeyPair
    }
  }
  //Adding children array to the parent:
  for (const folder in Object.keys(data)) {
    if (data[folder].parentId !== 'root') {
      const parentId = data[folder].parentId
      data[parentId].childrenId = [...data[parentId].childrenId, parseFloat(folder)]
    }
  }
  // console.log(data);
  return {
    id: Object.keys(data).length - 1,
    currentFolder: data[0].id,
    data,
  };
}
import "./ContactPage.css";
import { useEffect, useState, useContext } from "react";
import { FormattedMessage } from "react-intl";
import LanguageContext from "../../utils/Language";
import { useNavigate, Link } from "react-router-dom";

export const CourseEndPage = () => {
  const [email, setEmail] = useState("");
  const { language, setLanguage } = useContext(LanguageContext);
  const backgroundCSS =
    language === "fr"
      ? "ContactPage_background ContactPage_fr"
      : "ContactPage_background ContactPage_en";

  let navigate = useNavigate();
  function redirectToPrevious() {
    navigate(-1);
  }
  function redirectToHome() {
    navigate("/");
  }

  return (
    <div className={backgroundCSS}>
      <div className="ContactPage_overlay"></div>
      <div className="ContactPage_front">
        <div className="ContactPage_wrapper">
          <div className="ContactPage_closeButton" onClick={redirectToPrevious}>
            <span className="ContactPage_closeButtonText">x</span>
          </div>
          <span className="ContactPage_BrandText" onClick={redirectToHome}>
          WindowsProfessor.com
          </span>
          <h1 className="ContactPage_BrandText ContactPage_h1">
            <FormattedMessage
              id="CourseEndPage.h1.message"
              defaultMessage="Well done, you finished the course."
            />
          </h1>
          <span className="ContactPage_InstructionText">
            <FormattedMessage
              id="CourseEndPage.span.message1"
              defaultMessage="Do not hesitate to do it again until you feel comfortable with all the functions. We will let you know when new content is added."
            />
          </span>
          <span className="ContactPage_InstructionText">
            <FormattedMessage
              id="CourseEndPage.span.message2"
              defaultMessage="A question, a suggestion? contact us by e-mail:"
            />
          </span>
          <span className="ContactPage_InstructionText ContactPage_email">
            contact@interactivelearning.fr
          </span>
          <span className="ContactPage_InstructionText">
            <FormattedMessage
              id="CourseEndPage.span.message3"
              defaultMessage="Want to know more? "
            />
            <a href="https://twitter.com/indie_CL">
              <FormattedMessage
                id="CourseEndPage.a.Twitter"
                defaultMessage="connect on Twitter 😊"
              />
            </a>
          </span>
        </div>
        <span className="ContactPage_copyright">
        WindowsProfessor.com © 2023 - 2024
        </span>
      </div>
    </div>
  );
};

import "./WindowSideBar.css";
import { useState, useEffect, useContext } from "react";
import CurrentFolderContext from "../../../utils/CurrentFolder";
import FolderDataContext from "../../../utils/FolderData";
import { DroppableSideBarFolder } from "./DroppableSideBarFolder";

function WindowSideBar() {
  const { currentFolder, setCurrentFolder } = useContext(CurrentFolderContext);
  const { folderData, setFolderData } = useContext(FolderDataContext);
  //Display all folders that have a 0 parent Id
  //create a sub components for folders, map on it
  //folder is droppable area an on click or  double change current folder
  //on hover highlight
  return (
    <div className="WindowSideBar">
      <div className="WindowSideBarNav">
        {Object.keys(folderData).map((key) => {
          //console.log(folderData[currentFolder].childrenId)
          return (
            folderData[key].parentId === 'root' && (
              <DroppableSideBarFolder
                key={key}
                id={key}
                {...folderData[key]}
                value={folderData[key].title}
              />
            )
          );
        })}
      </div>
    </div>
  );
}

export default WindowSideBar;

import "./StatusBar.css";
import { useContext, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
// import LanguageContext from "../../utils/Language";
import LevelContext from "../../utils/Level";

export const StatusBar = ({ user, setUser }) => {
  const { level, setLevel } = useContext(LevelContext);
  let navigate = useNavigate();

  const handleClick = (source) => {
    if (source === "home") {
      navigate("/");
    }
    if (source === "login") {
      navigate("/logInUser");
    }
    if (source === "help") {
      navigate("/contact");
    }
    if (source === "disconnection") {
      //disconnection
      setUser((prevState) => ({
        ...prevState,
        email: "",
        urlId: "",
        isAuthenticated: false,
      }));
      //remove JWT and unauth
      localStorage.removeItem("login_access_token");
      //redirect
      navigate("/");
    }
  };
  // useEffect(() => {
  //   if (!user.isAuthenticated) {
  //     navigate("/");
  //   }
  // },[user])
  //className="StatusBar_front"
  return (
    <div className="StatusBar_div">
      {/* <div className="StatusBar_Logo" onClick={() => handleClick("home")}>
        IL
      </div> */}
      {user.isAuthenticated && (
        <span className="StatusBar_email">{user.email}</span>
      )}
      {user.isAuthenticated && (
        <div
          className="StatusBar_Logo StatusBar_shutdownButton"
          onClick={() => handleClick("disconnection")}
        >
          <img
            className="StatusBar_shutdownSVG"
            src="images/svg/shutdown.svg"
            alt="shutdownButton"
          />
        </div>
      )}
      {/* {!user.isAuthenticated && (
        <div
          className="StatusBar_Logo StatusBar_loginButton"
          onClick={() => handleClick("login")}
        >
          <span className="StatusBar_buttonTextAlternate">
            <FormattedMessage
              id="StatusBar.span.Login"
              defaultMessage="Log in"
            />
          </span>
        </div>
      )} */}
      <div className="StatusBar_Logo StatusBar_helpButton" onClick={() => handleClick("help")}>
        ?
      </div>
    </div>
  );
};

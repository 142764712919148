import "./LandingDesktop.css";
import { useEffect, useState, useContext } from "react";
import { FormattedMessage } from "react-intl";
import LanguageContext from "../../utils/Language";
import { useNavigate } from "react-router-dom";
import { StatusBar } from "../ControlBar/StatusBar";
import { ControlBarLanding } from "../ControlBar/ControlBarLanding";

export const LandingDesktop = ({ user, setUser }) => {
  const { language, setLanguage } = useContext(LanguageContext);
  const [objective, setObjective] = useState(
    language === "en" ? "create folders" : "créer des dossier"
  );

  const objectiveListEN = [
    "create folders",
    "copy and paste",
    "organize your folders",
    "navigate easily",
  ];
  const objectiveListFR = [
    "créer des dossier",
    "copier et coller",
    "organiser vos dossiers",
    "naviguer facilement",
  ];

  let navigate = useNavigate();
  const redirectToApp = (urlId) => {
    // props.updateTitle("Home");
    navigate(`/app/${urlId}`);
  };
  const redirectToLogin = () => {
    // props.updateTitle("Home");
    navigate(`/logInUser`);
  };
  const redirectToContact = () => {
    // props.updateTitle("Home");
    navigate(`/contact`);
  };

  const redirectToFAQ = () => {
    // props.updateTitle("Home");
    navigate(`/faq`);
  };

  useEffect(() => {
    // if (landingModalStatus) {

    let timer;
    if (language === "fr") {
      timer = setInterval(() => {
        setObjective(objectiveListFR[Math.floor(Math.random() * 4)]);
        //console.log("running");
      }, 1500);
    } else {
      timer = setInterval(() => {
        setObjective(objectiveListEN[Math.floor(Math.random() * 4)]);
        //console.log("running");
      }, 1500);
    } // clearing interval
    return () => clearInterval(timer);
    // }
  });

  //   <Link className={className} key={item.id} to={`/section/${item.id}`}>
  //   <SectionMenuItem key={item.id} id={item.id} title={item.title} />
  // </Link>
  const backgroundCSS =
    language === "fr"
      ? "LandingDesktop_background LandingDesktop_fr"
      : "LandingDesktop_background LandingDesktop_en";

  const redirectToHome = () => {
    // props.updateTitle("Login");
    navigate("/");
  };

  return (
    <div className={backgroundCSS}>
      <div className="LandingDesktop_overlay"></div>
      {/* <StatusBar user={user} /> */}
      <ControlBarLanding user={user} setUser={setUser} />
      <div className="LandingDesktop_front">
      <span
          className="LandingDesktop_Button_small"
        >
          <FormattedMessage
            id="LandingDesktop.span.changeNameMessage"
            defaultMessage="InteractiveLearning.fr is now WindowsProfessor.com"
          />
        </span>
        <span
          className="InstructionWindow_BrandText Login_clickable"
          onClick={redirectToHome}
        >
          WindowsProfessor.com
        </span>
        <h1 className="LandingDesktop_Text">
          <FormattedMessage
            id="LandingDesktop.h1.message"
            defaultMessage="Mastering Windows has never been easier."
          />
        </h1>

        <h2 className="LandingDesktop_Text LandingDesktop_TextDynamic">
          <FormattedMessage
            id="LandingDesktop.h2.message"
            defaultMessage="Be autonomous and learn to "
          />
          <span className="LandingDesktop_gradientText">{objective}</span>
        </h2>

        {!user.isAuthenticated && (
          <div
            className="LandingDesktop_ButtonAlternate"
            onClick={() => redirectToApp("course")}
            //onClick={() => redirectToApp("trial")}
          >
            <span className="LandingDesktop_buttonTextAlternate">
              <FormattedMessage
                id="LandingDesktop.span.CTA"
                defaultMessage="Try now an interactive exercise"
              />
            </span>
          </div>
        )}

        {!user.isAuthenticated && (
          <div className="LandingDesktop_ButtonContainer">
            {/* <div
              className="LandingDesktop_Button LandingDesktop_Button_small"
              onClick={() => redirectToLogin()}
            >
              <span className="LandingDesktop_buttonText">
                <FormattedMessage
                  id="LandingDesktop.span.login"
                  defaultMessage="Log in"
                />
              </span>
            </div> */}
            <div
              className="LandingDesktop_Button LandingDesktop_Button_small"
              onClick={() => redirectToFAQ()}
            >
              <span className="LandingDesktop_buttonText">
                <FormattedMessage
                  id="LandingDesktop.span.FAQ"
                  defaultMessage="Frequently Asked Questions"
                />
              </span>
            </div>
            <div
              className="LandingDesktop_Button LandingDesktop_Button_small"
              onClick={() => redirectToContact()}
            >
              <span className="LandingDesktop_buttonText">
                <FormattedMessage
                  id="LandingDesktop.span.contact"
                  defaultMessage="Contact"
                />
              </span>
            </div>
          </div>
        )}

        {user.isAuthenticated && (
          <div
            className="LandingDesktop_ButtonAlternate"
            onClick={() => redirectToApp(user.urlId)}
          >
            <span className="LandingDesktop_buttonTextAlternate">
              <FormattedMessage
                id="LandingDesktop.span.CTA.Authenticated"
                defaultMessage="Continue to the course"
              />
            </span>
          </div>
        )}

        {user.isAuthenticated && (
          <div className="LandingDesktop_ButtonContainer">
            <div
              className="LandingDesktop_Button LandingDesktop_Button_small"
              onClick={() => redirectToContact()}
            >
              <span className="LandingDesktop_buttonText">
                <FormattedMessage
                  id="LandingDesktop.span.contact"
                  defaultMessage="Contact"
                />
              </span>
            </div>
          </div>
        )}

        <span className="LandingDesktop_copyright">
        WindowsProfessor.com © 2023 - 2024
        </span>
        <div className="landingModal_languageContainer">
          <img
            onClick={() => {
              setLanguage("en");
            }}
            src="images/english.png"
            alt="english language icon"
            className="LandingDesktop_language_Button"
          />
          <img
            onClick={() => {
              setLanguage("fr");
            }}
            src="images/french.png"
            alt="french language icon"
            className="LandingDesktop_language_Button"
          />
        </div>
      </div>
    </div>
  );
};

import "./OneWindow.css";
import { useState, useEffect, useContext } from "react";
import LanguageContext from "../../utils/Language";
import CurrentFolderContext from "../../utils/CurrentFolder";
import FolderDataContext from "../../utils/FolderData";
import WindowHeader from "./WindowHeader";
import WindowNavBar from "./WindowNavBar";
import WindowBody from "./WindowBody";
import WindowSideBar from "./sideBar/WindowSideBar";
import WindowFooter from "./WindowFooter";
import { FolderMaker } from "../FolderInitiator";

function OneWindow(props) {
  // console.log(data);
  const { currentFolder, setCurrentFolder } = useContext(CurrentFolderContext);
  const { folderData, setFolderData } = useContext(FolderDataContext);
  const [id, setId] = useState(props.initialData.levelFolderData.id);
  const [copiedFolder, setCopiedFolder] = useState({});
  const { language, setLanguage } = useContext(LanguageContext);
  const [newFolderLanguage, setNewFolderLanguage] = useState(
    language === "en" ? "New folder" : "Nouveau dossier"
  );

  useEffect(() => {
    //console.log(props.actionFromMenu);
    if (props.actionFromMenu.action === "open") {
      const idClicked = parseFloat(props.actionFromMenu.idClicked);
      if (folderData[idClicked].type === "folder") {
        setCurrentFolder(idClicked);
      }
    }
    if (props.actionFromMenu.action === "addFolder") {
      // console.log("create folder");
      // const rand = Math.random().toString().substr(2, 8);
      const newFolder = FolderMaker(
        id + 1,
        "folder",
        newFolderLanguage,
        "folderEmpty.png",
        currentFolder,
        [],
        props.actionFromMenu.localY,
        props.actionFromMenu.localX
      );
      setId((prev) => prev + 1);
      newFolder.changeTitle = true;
      const updatedChildrenId = folderData[currentFolder].childrenId;
      updatedChildrenId.push(newFolder.id);
      const updatedCurrentFolder = {
        ...folderData[currentFolder],
        childrenId: updatedChildrenId,
      };
      setFolderData((folderData) => ({
        ...folderData,
        [newFolder.id]: newFolder,
        [currentFolder]: updatedCurrentFolder,
      }));
      //trigger Rename
    }
    if (props.actionFromMenu.action === "rename") {
      if (props.actionFromMenu.idClicked !== "windowZone") {
        const renamedFolder = {
          ...folderData[props.actionFromMenu.idClicked],
          changeTitle: true,
        };
        //console.log(updatedCurrentFolder);
        setFolderData((folderData) => ({
          ...folderData,
          [props.actionFromMenu.idClicked]: renamedFolder,
        }));
        //Replace folder span with an input field
        //placeholder is the previous name
        //obtain input name and update folderData
      }
    }

    if (props.actionFromMenu.action === "delete") {
      //check if the item clicked is a folder
      if (props.actionFromMenu.idClicked !== "windowZone") {
        // console.log("Delete a folder");
        const idClicked = parseFloat(props.actionFromMenu.idClicked);
        //remove deleted folder id from parent children array
        let originUpdatedChildrenId = folderData[currentFolder].childrenId;
        originUpdatedChildrenId = originUpdatedChildrenId.filter(
          (data) => data !== idClicked
        );
        const originFolder = {
          ...folderData[currentFolder],
          childrenId: originUpdatedChildrenId,
        };
        //we need to identify all the children from the deleted folder,to delete them as well
        let children = folderData[idClicked].childrenId;
        let folderToBeDeleted = children;
        //loop on this array to find child until all child array are empty
        //test if copied folder have child
        let isThereChildren;
        children.length === 0
          ? (isThereChildren = false)
          : (isThereChildren = true);
        //
        while (isThereChildren) {
          let nextChildren = [];
          for (const child of children) {
            folderToBeDeleted = [
              ...folderToBeDeleted,
              ...folderData[child].childrenId,
            ];
            nextChildren = [...folderData[child].childrenId];
          }
          nextChildren.length === 0
            ? (isThereChildren = false)
            : (children = nextChildren);
        }
        //console.log(folderToBeDeleted);
        //add the folder to be deleted to the array including its children
        folderToBeDeleted = [idClicked, ...folderToBeDeleted];
        //console.log(folderToBeDeleted);
        //remove them from Folder data
        folderToBeDeleted.forEach((child) => delete folderData[child]);
        //console.log(folderData);
        //update folder data
        setFolderData((folderData) => ({
          ...folderData,
          [currentFolder]: originFolder,
        }));
      }
    }
    if (props.actionFromMenu.action === "copy") {
      if (props.actionFromMenu.idClicked !== "windowZone") {
        //Get clicked folder id
        // console.log(props.actionFromMenu.idClicked);
        const idClicked = parseFloat(props.actionFromMenu.idClicked);
        //update the state of copied Folder with this clicked folder data and
        //all the children of the copied folder at the moment of the click
        let children = folderData[idClicked].childrenId;
        let copiedFolderAllChildren = children;
        //loop on this array to find child until all child array are empty
        //test if copied folder have child
        let isThereChildren;
        children.length === 0
          ? (isThereChildren = false)
          : (isThereChildren = true);
        //
        while (isThereChildren) {
          let nextChildren = [];
          for (const child of children) {
            copiedFolderAllChildren = [
              ...copiedFolderAllChildren,
              ...folderData[child].childrenId,
            ];
            nextChildren = [...folderData[child].childrenId];
            //console.log(copiedFolderAllChildren);
          }
          nextChildren.length === 0
            ? (isThereChildren = false)
            : (children = nextChildren);
        }
        //console.log(copiedFolderAllChildren);
        //Create a Copied folder object that has the same organization has FolderData
        //but only with the copied folder and its children inside
        //We add the main (parent) copied folder and modified its parent attribute to identify him has the root
        const updatedCurrentFolder = {
          ...folderData[idClicked],
          parentId: "root",
        };
        //
        let copiedFolderObject = {
          [idClicked]: updatedCurrentFolder,
        };
        copiedFolderAllChildren.forEach(
          (child) =>
            (copiedFolderObject = {
              ...copiedFolderObject,
              [child]: folderData[child],
            })
        );
        setCopiedFolder(copiedFolderObject);
      }
    }
    if (props.actionFromMenu.action === "paste") {
      //remain to be done: check if folder name already exist within the children
      //of current folder, if yes , ask to replace/merge/rename or cancel OP...

      //Get current folder id => currentFolder
      //obtain the id of the root folder within copiedFolder
      let rootFolderId = Object.keys(copiedFolder).filter((folderId) => {
        return copiedFolder[folderId].parentId === "root";
      });
      rootFolderId = parseFloat(rootFolderId);
      //console.log(rootFolderId);
      let pasteFolderId = rootFolderId + id - 1;
      const deltaId = pasteFolderId - rootFolderId;
      //console.log(deltaId);
      let pastedFolderObject = {};
      //console.log(pastedFolderObject);
      let maxId = id;
      for (const folder of Object.keys(copiedFolder)) {
        if (copiedFolder[folder].parentId === "root") {
          const updatedChildrenArray = copiedFolder[folder].childrenId.map(
            (child) => child + deltaId
          );
          let mainPasteFolder = {
            ...copiedFolder[folder],
            id: pasteFolderId,
            parentId: folderData[currentFolder].id,
            top: props.actionFromMenu.localY,
            left: props.actionFromMenu.localX,
            childrenId: updatedChildrenArray,
          };
          pastedFolderObject = {
            ...pastedFolderObject,
            [mainPasteFolder.id]: mainPasteFolder,
          };
          if (mainPasteFolder.id > maxId) {
            maxId = mainPasteFolder.id;
          }
        } else {
          const updatedChildrenArray = copiedFolder[folder].childrenId.map(
            (child) => child + deltaId
          );
          let childrenFolder = {
            ...copiedFolder[folder],
            id: copiedFolder[folder].id + deltaId,
            parentId: copiedFolder[folder].parentId + deltaId,
            childrenId: updatedChildrenArray,
          };
          pastedFolderObject = {
            ...pastedFolderObject,
            [childrenFolder.id]: childrenFolder,
          };
          if (childrenFolder.id > maxId) {
            maxId = childrenFolder.id;
          }
        }
      }
      setId(maxId + 1);
      // id = maxId + 1;
      //console.log(pastedFolderObject);
      //update current folder children array with pasted folder id
      const updatedChildrenId = folderData[currentFolder].childrenId;
      updatedChildrenId.push(pasteFolderId);
      const updatedCurrentFolder = {
        ...folderData[currentFolder],
        childrenId: updatedChildrenId,
      };
      //insert in Folder data the copied folder
      setFolderData((folderData) => ({
        ...folderData,
        ...pastedFolderObject,
        [currentFolder]: updatedCurrentFolder,
      }));
      //set the id to the max of Folder data
    }
  }, [props.actionFromMenu.action]);
  //console.log(id);

  return (
    <div className="OneWindow">
      <WindowHeader />
      <WindowNavBar />
      <div className="sideAndBody">
        <WindowSideBar />
        <WindowBody />
      </div>
      <WindowFooter />
    </div>
  );
}

export default OneWindow;

import { useContext, useEffect, useState } from "react";
import { useContextMenu } from "../../utils/useContextMenu";
import "./ContextMenu.css";
import LevelContext from "../../utils/Level";
import { FolderContextMenu } from "./FolderContextMenu";
import { EmptyAreaContextMenu } from "./EmptyAreaContextMenu";
// 2
export const ContextMenu = (props) => {
  const { xPos, yPos, showMenu, action, idClicked } = useContextMenu();

  const [helper, setHelper] = useState({
    display: false,
    message: "",
    id: "",
  });
  const { level, setLevel } = useContext(LevelContext);

  useEffect(() => {
    var offsets = document
      .getElementById("WindowBodyDragZone")
      .getBoundingClientRect();
    var top = offsets.top;
    var left = offsets.left;
    const localX = Math.round((parseFloat(xPos) - left) * 100) / 100;
    const localY = Math.round((parseFloat(yPos) - top) * 100) / 100;
    const feedbackMenu = { action, idClicked, localX, localY };
    // console.log(feedbackMenu);
    props.value(feedbackMenu);
  }, [action]);

  useEffect(() => {
    //check if the level steps include a generalModal id.
    const isThereHelper = Object.keys(level)
      .map((key) => {
        return level[key].id === "contextMenuHelper" ? key : false;
      })
      .filter((key) => key !== false);
    //if yes, extract the  info
    if (isThereHelper[0]) {
      setHelper((previous) => ({
        ...previous,
        display: level[isThereHelper[0]].toBeDisplayed,
        message: level[isThereHelper[0]].message,
        id: isThereHelper[0],
      }));
    }
  }, [level]);

  const windowZone = idClicked === "windowZone" ? true : false;

  return showMenu ? (
    windowZone ? (
      <EmptyAreaContextMenu yPos={yPos} xPos={xPos} helper={helper} />
    ) : (
      <FolderContextMenu yPos={yPos} xPos={xPos} helper={helper} />
    )
  ) : (
    <></>
  );
};

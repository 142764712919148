//import { memo } from "react";
import "./SideBarFolder.css";
import FolderDataContext from "../../../utils/FolderData";
import CurrentFolderContext from "../../../utils/CurrentFolder";
import { useContext, useState, useEffect } from "react";
import LevelContext from "../../../utils/Level";
//export const Folder = memo(function Folder({ title, yellow, preview }) {
//});
export const SideBarFolder = ({ id, title, picture, isTargeted }) => {
  const { folderData, setFolderData } = useContext(FolderDataContext);
  const { currentFolder, setCurrentFolder } = useContext(CurrentFolderContext);
  const [isSelected, setIsSelected] = useState(false);
  const { level, setLevel } = useContext(LevelContext);
  //"images/folderEmpty.png"
  //if current folder = this folder id then add the class selected and selected over
  //if the current folder is a child of this folder id add the class children (grey)
  //className={isSelected ? "selected folder" : "folder"}

  useEffect(() => {
    if (id === currentFolder) {
      setIsSelected(true);
    } else {
      setIsSelected(false);
    }
  }, [currentFolder]);
  //level.SideBarHighlight && level.SideBarHighlightId.includes(id) folderHighlight
  let sideFolderClasses = ''
  if (isSelected && !level.SideBarHighlightId.includes(id)) {
    sideFolderClasses = 'WindowSideBarNavLinkBox WindowSideBarNavLinkBoxSelected'
  } 
  else if(isSelected && level.SideBarHighlight && level.SideBarHighlightId.includes(id)) {
    sideFolderClasses = 'WindowSideBarNavLinkBox WindowSideBarNavLinkBoxSelected sideFolderHighlight'
  }
  else if(isTargeted && !level.SideBarHighlightId.includes(id)) {
    sideFolderClasses = 'WindowSideBarNavLinkBox WindowSideBarNavLinkBoxHover WindowSideBarNavLinkBoxIsTargeted'
  }
  else if(isTargeted && level.SideBarHighlight && level.SideBarHighlightId.includes(id)) {
    sideFolderClasses = 'WindowSideBarNavLinkBox WindowSideBarNavLinkBoxHover WindowSideBarNavLinkBoxIsTargeted sideFolderHighlight'
  }
  else if(level.SideBarHighlightId.includes(id)) {
    sideFolderClasses = 'WindowSideBarNavLinkBox WindowSideBarNavLinkBoxHover sideFolderHighlight'
  }
  else {
    sideFolderClasses = 'WindowSideBarNavLinkBox WindowSideBarNavLinkBoxHover'
  }
  return (
    <div
      // className="WindowSideBarNavLinkBox"
      // className={
      //   isSelected
      //     ? "WindowSideBarNavLinkBox WindowSideBarNavLinkBoxSelected"
      //     : isTargeted
      //     ? "WindowSideBarNavLinkBox WindowSideBarNavLinkBoxHover WindowSideBarNavLinkBoxIsTargeted"
      //     : "WindowSideBarNavLinkBox WindowSideBarNavLinkBoxHover"
      // }
      className={sideFolderClasses}
      id={id}
    >
      <img
        src={`images/${picture}`}
        alt="FolderPicture"
        className="WindowSideBarIcon"
      />
      <span className="WindowSideBarFolderText">{title}</span>
    </div>
  );
};

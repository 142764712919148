import "./FAQ.css";
import { FormattedMessage } from "react-intl";
import LanguageContext from "../../utils/Language";
import { useContext } from "react";
import FAQEn from "./FAQEn";
import FAQFr from "./FAQFr";
import { useNavigate } from "react-router-dom";

function FAQ() {
  const { language, setLanguage } = useContext(LanguageContext);
  let navigate = useNavigate();
  const redirectToHome = () => {
    navigate("/");
  };
  //
  return (
    <div className="legal">
      <h1
        className="legal_gradientText legal_clickable"
        onClick={redirectToHome}
      >
        WindowsProfessor.com
      </h1>
      <div className="mobileApp_languageContainer">
        <img
          onClick={() => {
            setLanguage("en");
          }}
          src="images/english.png"
          alt="english language icon"
          className="mobileApp_language_Button"
        />
        <img
          onClick={() => {
            setLanguage("fr");
          }}
          src="images/french.png"
          alt="English language icon"
          className="mobileApp_language_Button"
        />
      </div>

      {language === "fr" ? <FAQFr /> : <FAQEn />}

      <span className="mobileApp_copyright">
      WindowsProfessor.com © 2023 - 2024
      </span>
    </div>
  );
}

export default FAQ;

import "./WindowFooter.css";
import CurrentFolderContext from "../../utils/CurrentFolder";
import FolderDataContext from "../../utils/FolderData";
import { useContext } from "react";
import LanguageContext from "../../utils/Language";

function WindowFooter() {
  const { language, setLanguage } = useContext(LanguageContext);
  const { folderData, setFolderData } = useContext(FolderDataContext);
  const { currentFolder, setCurrentFolder } = useContext(CurrentFolderContext);
  const getNumberOfItems = () => {
    return folderData[currentFolder].childrenId.length;
  };
  const numberOfItems = getNumberOfItems();
  const items = language === 'en' ? "item(s)" : "élément(s)";
  return (
    <div className="WindowFooter">
      <span className="WindowFooterText">{`${numberOfItems} ${items}`}</span>
    </div>
  );
}

export default WindowFooter;

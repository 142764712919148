import "./WindowNavBar.css";
import { useContext, useEffect, useRef, useState } from "react";
import CurrentFolderContext from "../../utils/CurrentFolder";
import FolderDataContext from "../../utils/FolderData";
import LanguageContext from "../../utils/Language";
import LevelContext from "../../utils/Level";

function WindowNavBar() {
  const { currentFolder, setCurrentFolder } = useContext(CurrentFolderContext);
  const { folderData, setFolderData } = useContext(FolderDataContext);
  const { level, setLevel } = useContext(LevelContext);
  const [nextFolderExist, setNextFolderExist] = useState(false);
  const [previousFolderExist, setPreviousFolderExist] = useState(false);
  const [upFolderExist, setUpFolderExist] = useState(false);
  const { language, setLanguage } = useContext(LanguageContext);
  const folderHistoryArray = useRef([]);
  const folderForwardArray = useRef([]);

  const path = [];
  //Build the path field:
  if (folderData[currentFolder].parentId !== "root") {
    //let parentId = currentFolder.parentId;
    let parentId = folderData[currentFolder].parentId;
    while (folderData[parentId].parentId !== "root") {
      path.unshift({
        title: folderData[parentId].title,
        id: folderData[parentId].id,
      });
      parentId = folderData[parentId].parentId;
    }
    path.unshift({
      title: folderData[parentId].title,
      id: folderData[parentId].id,
    });
  }

  path.push({
    title: folderData[currentFolder].title,
    id: folderData[currentFolder].id,
  });
  function handleUpArrow() {
    const parentId = folderData[currentFolder].parentId;
    if (parentId !== "root") {
      setCurrentFolder(folderData[parentId].id);
    }
  }
  useEffect(() => {
    const parentId = folderData[currentFolder].parentId;
    // console.log(folderHistoryArray.current);
    if (parentId !== "root") {
      setUpFolderExist(true);
    } else {
      setUpFolderExist(false);
    }
  }, [currentFolder]);

  const changePath = (e) => {
    const id = parseFloat(e.target.id);
    setCurrentFolder(id);
  };

  useEffect(() => {
    folderHistoryArray.current.push(currentFolder);
  }, [currentFolder]);
  //arrow not available 223	223	223	hex : DFDFDF
  //arrow hover rgb(205, 232, 254);
  //blue arrow 33	163	251	 -> #21A3FB
  //
  const goToPreviousLocation = () => {
    if (folderHistoryArray.current.length > 1) {
      setCurrentFolder(
        folderHistoryArray.current[folderHistoryArray.current.length - 2]
      );
      folderForwardArray.current.unshift(currentFolder);
      folderHistoryArray.current.pop();
      folderHistoryArray.current.pop();
    }
  };
  const goToForwardLocation = () => {
    if (folderForwardArray.current.length) {
      setCurrentFolder(folderForwardArray.current[0]);
      folderForwardArray.current.shift();
    }
  };
  useEffect(() => {
    if (folderForwardArray.current.length) {
      setNextFolderExist(true);
    } else {
      setNextFolderExist(false);
    }
  }, [currentFolder]);

  useEffect(() => {
    // console.log(folderHistoryArray.current);
    if (folderHistoryArray.current.length > 1) {
      setPreviousFolderExist(true);
    } else {
      setPreviousFolderExist(false);
    }
  }, [currentFolder]);
  // console.log(folderHistoryArray.current);

  return (
    <div className="navBar">
      <div className="navControlBox">
        {previousFolderExist ? (
          <img
            //className="arrowLeftButton navBarArrow navBarArrowHover"
            //removed arrowLeftButton
            className={`navBarArrow navBarArrowHover ${
            level.WindowNavBarArrowLeft && "arrowHighlight"
          }`}
            src="images/svg/arrowLeft.svg"
            alt="arrowRightButton"
            onClick={goToPreviousLocation}
          />
        ) : (
          <img
            //removed arrowLeftButton
            className="navBarArrow"
            src="images/svg/arrowLeftLightGrey.svg"
            alt="arrowRightButton"
          />
        )}

        {nextFolderExist ? (
          <img
            //className="navBarArrow navBarArrowHover"
            className={`navBarArrow navBarArrowHover ${
            level.WindowNavBarArrowRight && "arrowHighlight"
          }`}
            src="images/svg/arrowRight.svg"
            alt="arrowRightButton"
            onClick={goToForwardLocation}
          />
        ) : (
          <img
            className="navBarArrow"
            src="images/svg/arrowRightLightGrey.svg"
            alt="arrowRightButton"
          />
        )}

        <img
          className="navBarChevron"
          src="images/svg/chevronDownBold.svg"
          alt="ChevronDownButton"
        />

        {upFolderExist ? (
          <img
            // className="navBarArrow navBarArrowUp"
            className={`navBarArrow navBarArrowUp ${
            level.WindowNavBarArrowUp && "arrowHighlight"
          }`}
            src="images/svg/arrowUp.svg"
            alt="arrowUpButton"
            onClick={handleUpArrow}
          />
        ) : (
          <img
            className="navBarArrow"
            src="images/svg/arrowUpLightGrey.svg"
            alt="arrowUpButton"
          />
        )}

      </div>
      {/* <span className='navArrow' onClick= {handleUpArrow}>&#8593;</span>  */}
      <div className="navBarPathField">
        <div className="navBarPathInitialFolderWrapper">
          <img
            className="navBarPathFolderImage"
            src={`images/${folderData[currentFolder].picture}`}
            alt="ChevronRightButton"
          />
          <img
            className="navBarPathFirstChevron"
            src="images/svg/chevronRightBold.svg"
            alt="ChevronRightButton"
          />
        </div>
        {path.map((folder) => (
          <div
            className="navBarPathFolderWrapper"
            onClick={changePath}
            id={folder.id}
            key={folder.id}
          >
            <span 
            // className="navBarPathFolder" 
            className={`navBarPathFolder ${
            level.NavigationBarHighlight && level.NavigationBarHighlightId.includes(folder.id) && "arrowHighlight"
          }`}
            id={folder.id}>
              {folder.title}
            </span>
            <img
              className="navBarPathChevron"
              src="images/svg/chevronRightBold.svg"
              alt="ChevronRightButton"
              id={folder.id}
            />
          </div>
        ))}
      </div>
      {/* <span className="navBarPathField">{pathString}</span> */}
      <div className="navBarSearchField">
        <img
          className="navBarSearchButton"
          src="images/svg/searchGrey.svg"
          alt="navBarSearchButton"
        />
        <span className="navBarSearchText">
          {language === 'en' ? "Search" : "Rechercher"}
        </span>
        {/* <span className="navBarSearchText">
          Search {folderData[currentFolder].title}
        </span> */}
      </div>
    </div>
  );
}

export default WindowNavBar;

import "./ModalWrapper.css";
import Modal from "react-modal";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";

Modal.setAppElement("#root");

export const SuccessModal = ({ successModalStatus, setSuccessModalStatus }) => {
  const [successModalStatusInternal, setSuccessModalStatusInternal] = useState(false);

  //mechanism to close the modal on cta click rather than waiting for level update (issue when live on vercel with delay)
  const closeToNextLevel = () => {
    setSuccessModalStatus(true)
    setSuccessModalStatusInternal(false)
  }

  useEffect(()=>{
    setSuccessModalStatusInternal(successModalStatus)
  },[successModalStatus])

  return (
    <Modal
      isOpen={successModalStatusInternal}
      // isOpen={successModalStatus}
      className="Modal"
      overlayClassName="Overlay"
      contentLabel="Example Modal"
      shouldFocusAfterRender={false}
    >
      <h1 className="modalText">
        <FormattedMessage
          id="SuccessModal.h1.message"
          defaultMessage="Congratulations!"
        />
      </h1>

      <div
        onClick={closeToNextLevel}
        // onClick={() => setSuccessModalStatus(true)}
        className="modalButtonAlternate"
      >
        <span className="buttonTextAlternate">
        <FormattedMessage
          id="SuccessModal.span.CTA.Button"
          defaultMessage="Click here to do the next exercise"
        />
        </span>
      </div>
      <div
        onClick={() => setSuccessModalStatus(false)}
        className="modalButton modalButton_small"
      >
        <span className="buttonText">
        <FormattedMessage
          id="SuccessModal.span.About"
          defaultMessage="About WindowsProfessor.com"
        />
        </span>
      </div>
    </Modal>
  );
};

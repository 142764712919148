import "./ControlBar.css";
import { useContext } from "react";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";
// import LanguageContext from "../../utils/Language";
import LevelContext from "../../utils/Level";
import Language from "../../utils/Language";
import { StatusBar } from "./StatusBar";

export const ControlBar = (props) => {
  // console.log(props);
  const { level, setLevel } = useContext(LevelContext);
  let navigate = useNavigate();
  function redirectToContact() {
    navigate("/contact");
  }
  function redirectToHome() {
    navigate("/");
  }

  const handleClick = (source) => {
    if (source === "previous" && level.levelId > 1) {
      //next exercise
      const nextLevelId = level.levelId - 1;
      setLevel((previous) => ({ ...previous, levelId: nextLevelId }));
    }
    if (source === "next" && level.levelId < 24) {
      //next exercise
      const nextLevelId = level.levelId + 1;
      setLevel((previous) => ({ ...previous, levelId: nextLevelId }));
    }
    if (source === "next" && level.levelId === 24) {
      setLevel((previous) => ({ ...previous, success: false }));
      redirectToContact();
    }
    if (source === "restart") {
      props.setRestart(true);
    }
  };

  return (
    <div className="ControlBar_front">
      <div className="StatusBar_Logo" onClick={redirectToHome}>
        WP
      </div>
      <div className="ControlBar_levelDiv">
        <div
          className="ControlBar_ButtonAlternate ControlBar_GradientLeft"
          onClick={() => handleClick("previous")}
        >
          <span className="ControlBar_buttonTextAlternate">
            <FormattedMessage
              id="ControlBar.span2.CTA"
              defaultMessage="Previous"
            />
          </span>
        </div>
        <div
          className="ControlBar_reloadButton"
          onClick={() => handleClick("restart")}
        >
          {/* <span className="ControlBar_buttonTextAlternate">
            <FormattedMessage
              id="ControlBar.span1.CTA"
              defaultMessage="Reset level"
            />
          </span> */}
          <img
            className="ControlBar_reloadButton_svg"
            src="images/svg/reload.svg"
            alt="reloadButton"
          />
        </div>
        <span className="ControlBar_level">
          {props.levelList[props.language][level.levelId]}
        </span>

        <div
          className="ControlBar_ButtonAlternate ControlBar_GradientRight"
          onClick={() => handleClick("next")}
        >
          <span className="ControlBar_buttonTextAlternate">
            <FormattedMessage id="ControlBar.span3.CTA" defaultMessage="Next" />
          </span>
        </div>
      </div>
      <StatusBar user={props.user} setUser={props.setUser} />
    </div>
  );
};

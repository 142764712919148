//import { memo } from 'react';
import { useDrop } from "react-dnd";
import { ItemTypes } from "../../../utils/items";
import { SideBarFolder } from "./SideBarFolder";
import { useContext, useRef, useState } from "react";
import CurrentFolderContext from "../../../utils/CurrentFolder";
import FolderDataContext from "../../../utils/FolderData";

export const DroppableSideBarFolder = (props) => {
  //console.log('inDraggable');
  const ref = useRef(null);
  const {
    id,
    title,
    picture,
  } = props;
  const { currentFolder, setCurrentFolder } = useContext(CurrentFolderContext);
  const { folderData, setFolderData } = useContext(FolderDataContext);
  //console.log(`${id}: ${changeTitle}`);
  // console.log(currentFolder);

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: [ItemTypes.FOLDER,ItemTypes.FILE],
      drop(item, monitor) {
        //get drag folder id: item.id
        //get dropped over folder id : id
        //remove dragged folder id from parent children array
        //avoid self drop
        if (id !== item.id) {
          let originUpdatedChildrenId = folderData[currentFolder].childrenId;
          originUpdatedChildrenId = originUpdatedChildrenId.filter(
            (data) => data !== item.id
          );
          const originFolder = {
            ...folderData[currentFolder],
            childrenId: originUpdatedChildrenId,
          };
          // console.log(originFolder);
          //add dragged folder id to dropped folder children array
          let destinationUpdatedChildrenId = folderData[id].childrenId;
          destinationUpdatedChildrenId.push(item.id);
          const destinationFolder = {
            ...folderData[id],
            childrenId: destinationUpdatedChildrenId,
          };
          //change dragged folder parent id to drop folder id
          const movedFolder = {
            ...folderData[item.id],
            parentId: folderData[id].id,
          };
          //update folderData state
          setFolderData((folderData) => ({
            ...folderData,
            [currentFolder]: originFolder,
            [id]: destinationFolder,
            [item.id]: movedFolder,
          }));
        }
        //return undefined;
      },
      collect: (monitor) => ({
        isOver: monitor.isOver(),
      }),
    }),
    [folderData,currentFolder]
  );
  // console.log(isOver);
  drop(ref);

  function changePath() {
      setCurrentFolder(folderData[id].id);
  }
  const preventDefault = (e) => e.preventDefault(); //to prevent firefox opening the folder picture on drop
  //https://github.com/react-dnd/react-dnd/issues/3179

  return (
    <div
      ref={ref}
      id={id}
      onDoubleClick={changePath}
      onClick={changePath}
      data-key={props.id}
      onDrop={preventDefault}
      className="sideBarDroppableArea"
    >
      <SideBarFolder
        id={id}
        title={title}
        picture={picture}
        isTargeted={isOver}
      />
    </div>
  );
};

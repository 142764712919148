import "./ContactPage.css";
import { useEffect, useState, useContext } from "react";
import { FormattedMessage } from "react-intl";
import LanguageContext from "../../utils/Language";
import { useNavigate, Link } from "react-router-dom";

export const ContactPage = () => {
  const [email, setEmail] = useState("");
  const { language, setLanguage } = useContext(LanguageContext);
  const backgroundCSS =
    language === "fr"
      ? "ContactPage_background ContactPage_fr"
      : "ContactPage_background ContactPage_en";

  function handleChange(event) {
    const { value } = event.target;
    setEmail(value);
  }
  let navigate = useNavigate();
  function redirectToPrevious() {
    navigate(-1);
  }
  function redirectToHome() {
    navigate("/");
  }
  function redirectToLegal() {
    navigate("/legal");
  }

  // async function handleClick() {
  //   try {
  //     // POST request using fetch with async/await
  //     const requestOptions = {
  //       method: "POST",
  //       headers: { "Content-Type": "application/json" },
  //       body: JSON.stringify({ email: email }),
  //     };
  //     const response = await fetch(
  //       "https://interactive-learning-be.herokuapp.com/api/user",
  //       requestOptions
  //     );
  //     const data = await response.json();
  //     // console.log(data);
  //   } catch (error) {
  //     // console.log(error);
  //   }
  // }

  return (
    <div className={backgroundCSS}>
      <div className="ContactPage_overlay"></div>
      <div className="ContactPage_front">
        <div className="ContactPage_wrapper">
          <div className="ContactPage_closeButton" onClick={redirectToPrevious}>
            <span className="ContactPage_closeButtonText">x</span>
          </div>
          <span className="ContactPage_BrandText" onClick={redirectToHome}>
          WindowsProfessor.com
          </span>
          <h1 className="ContactPage_InstructionText">
            <FormattedMessage
              id="ContactPage.h1.message"
              // defaultMessage="A question? An issue with your purchase? contact us by e-mail:"
              defaultMessage="A question? contact us by e-mail:"
            />
          </h1>
          <span className="ContactPage_InstructionText">
            contact@interactivelearning.fr
          </span>
          {/* <span className="ContactPage_InstructionText">
            <FormattedMessage
              id="ContactPage.span.CTA.Email"
              defaultMessage="Send me an email when new content is published"
            />
          </span> */}
          {/* <form className="ContactPage_form" method="post" action="/">
            <input
              type="email"
              className="ContactPage_input"
              name="email"
              placeholder="E-mail"
              onChange={handleChange}
              value={email}
            />
            <div
              onClick={handleClick}
              className="ContactPage_modalButton ContactPage_modalButton_small ContactPage_contactModal_button"
            >
              <span className="ContactPage_buttonText">
                <FormattedMessage
                  id="ContactPage.span.Email.Button"
                  defaultMessage="Sign me up"
                />
              </span>
            </div>
          </form> */}
          <span className="ContactPage_InstructionText">
            <FormattedMessage
              id="ContactPage.span.Twitter.1"
              defaultMessage="Want to know more? "
            />
            <a href="https://twitter.com/indie_CL">
              <FormattedMessage
                id="ContactPage.a.Twitter.2"
                defaultMessage="connect on Twitter 😊"
              />
            </a>
          </span>
        </div>
        {/* <span className="ContactPage_legal" onClick={redirectToLegal}>
          <FormattedMessage
            id="contact.span.tos"
            defaultMessage="Terms of service & privacy policy"
          />
        </span> */}
        <span className="ContactPage_copyright">
        WindowsProfessor.com © 2023 - 2024
        </span>
      </div>
    </div>
  );
};

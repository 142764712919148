import "./InstructionWindow.css";
import { useState, useContext } from "react";
import { FormattedMessage } from "react-intl";
import LanguageContext from "../../utils/Language";

export const InstructionWindow = (props) => {
  const [objective, setObjective] = useState("create folders");
  const { language, setLanguage } = useContext(LanguageContext);

  return (
    <div className="InstructionWindow_container">
      <h1 className="InstructionWindow_BrandText">WindowsProfessor.com</h1>
      <props.initialData.LevelInstruction />
    </div>
  );
};
